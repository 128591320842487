/* global window, Backbone, $, arangoHelper */
(function () {
  'use strict';

  window.arangoCollectionModel = Backbone.Model.extend({
    idAttribute: 'name',
    urlRoot: arangoHelper.databaseUrl('/_api/collection'),
    defaults: {
      id: '',
      name: '',
      status: '',
      type: '',
      isSystem: false,
      picture: '',
      locked: false,
      desc: undefined
    },
    getProperties: function getProperties(callback) {
      $.ajax({
        type: 'GET',
        cache: false,
        url: arangoHelper.databaseUrl('/_api/collection/' + encodeURIComponent(this.get('id')) + '/properties'),
        contentType: 'application/json',
        processData: false,
        success: function success(data) {
          callback(false, data);
        },
        error: function error(data) {
          callback(true, data);
        }
      });
    },
    getFigures: function getFigures(callback) {
      $.ajax({
        type: 'GET',
        cache: false,
        url: arangoHelper.databaseUrl('/_api/collection/' + this.get('id') + '/figures'),
        contentType: 'application/json',
        processData: false,
        success: function success(data) {
          callback(false, data);
        },
        error: function error() {
          callback(true);
        }
      });
    },
    getRevision: function getRevision(callback, figures) {
      $.ajax({
        type: 'GET',
        cache: false,
        url: arangoHelper.databaseUrl('/_api/collection/' + this.get('id') + '/revision'),
        contentType: 'application/json',
        processData: false,
        success: function success(data) {
          callback(false, data, figures);
        },
        error: function error() {
          callback(true);
        }
      });
    },
    getIndex: function getIndex(callback) {
      var self = this;
      $.ajax({
        type: 'GET',
        cache: false,
        url: arangoHelper.databaseUrl('/_api/index/?collection=' + this.get('id')),
        contentType: 'application/json',
        processData: false,
        success: function success(data) {
          callback(false, data, self.get('id'));
        },
        error: function error(data) {
          callback(true, data, self.get('id'));
        }
      });
    },
    createIndex: function createIndex(postParameter, callback) {
      var self = this;
      $.ajax({
        cache: false,
        type: 'POST',
        url: arangoHelper.databaseUrl('/_api/index?collection=' + self.get('id')),
        headers: {
          'x-arango-async': 'store'
        },
        data: JSON.stringify(postParameter),
        contentType: 'application/json',
        processData: false,
        success: function success(data, textStatus, xhr) {
          if (xhr.getResponseHeader('x-arango-async-id')) {
            window.arangoHelper.addAardvarkJob({
              id: xhr.getResponseHeader('x-arango-async-id'),
              type: 'index',
              desc: 'Creating Index',
              collection: self.get('id')
            });
            callback(false, data);
          } else {
            callback(true, data);
          }
        },
        error: function error(data) {
          callback(true, data);
        }
      });
    },
    deleteIndex: function deleteIndex(id, callback) {
      var self = this;
      $.ajax({
        cache: false,
        type: 'DELETE',
        url: arangoHelper.databaseUrl('/_api/index/' + this.get('name') + '/' + encodeURIComponent(id)),
        headers: {
          'x-arango-async': 'store'
        },
        success: function success(data, textStatus, xhr) {
          if (xhr.getResponseHeader('x-arango-async-id')) {
            window.arangoHelper.addAardvarkJob({
              id: xhr.getResponseHeader('x-arango-async-id'),
              type: 'index',
              desc: 'Removing Index',
              collection: self.get('id')
            });
            callback(false, data);
          } else {
            callback(true, data);
          }
        },
        error: function error(data) {
          callback(true, data);
        }
      });
      callback();
    },
    truncateCollection: function truncateCollection() {
      var self = this;
      $.ajax({
        cache: false,
        type: 'PUT',
        url: arangoHelper.databaseUrl('/_api/collection/' + this.get('id') + '/truncate'),
        success: function success() {
          arangoHelper.arangoNotification('Collection truncated.');
        },
        error: function error(err) {
          arangoHelper.arangoError('Collection error: ' + err.responseJSON.errorMessage);
        }
      });
    },
    warmupCollection: function warmupCollection() {
      $.ajax({
        cache: false,
        type: 'PUT',
        url: arangoHelper.databaseUrl('/_api/collection/' + this.get('id') + '/loadIndexesIntoMemory'),
        success: function success() {
          arangoHelper.arangoNotification('Loading indexes into memory.');
        },
        error: function error(err) {
          arangoHelper.arangoError('Collection error: ' + err.responseJSON.errorMessage);
        }
      });
    },
    loadCollection: function loadCollection(callback) {
      $.ajax({
        cache: false,
        type: 'PUT',
        url: arangoHelper.databaseUrl('/_api/collection/' + this.get('id') + '/load'),
        success: function success() {
          callback(false);
        },
        error: function error() {
          callback(true);
        }
      });
      callback();
    },
    unloadCollection: function unloadCollection(callback) {
      $.ajax({
        cache: false,
        type: 'PUT',
        url: arangoHelper.databaseUrl('/_api/collection/' + this.get('id') + '/unload?flush=true'),
        success: function success() {
          callback(false);
        },
        error: function error() {
          callback(true);
        }
      });
      callback();
    },
    renameCollection: function renameCollection(name, callback) {
      var self = this;
      $.ajax({
        cache: false,
        type: 'PUT',
        url: arangoHelper.databaseUrl('/_api/collection/' + this.get('id') + '/rename'),
        data: JSON.stringify({
          name: name
        }),
        contentType: 'application/json',
        processData: false,
        success: function success() {
          self.set('name', name);
          callback(false);
        },
        error: function error(data) {
          callback(true, data);
        }
      });
    },
    changeCollection: function changeCollection(wfs, replicationFactor, writeConcern, callback) {
      var result = false;

      if (wfs === 'true') {
        wfs = true;
      } else if (wfs === 'false') {
        wfs = false;
      }

      var data = {
        waitForSync: wfs
      };

      if (replicationFactor) {
        data.replicationFactor = parseInt(replicationFactor, 10);
      }

      if (writeConcern) {
        // not an error. writeConcern is stored in minReplicationFactor for historical reasons
        data.minReplicationFactor = parseInt(writeConcern, 10);
      }

      $.ajax({
        cache: false,
        type: 'PUT',
        url: arangoHelper.databaseUrl('/_api/collection/' + this.get('id') + '/properties'),
        data: JSON.stringify(data),
        contentType: 'application/json',
        processData: false,
        success: function success() {
          callback(false);
        },
        error: function error(data) {
          callback(true, data);
        }
      });
      return result;
    },
    changeValidation: function changeValidation(validation, callback) {
      var result = false;

      if (!validation) {
        validation = null;
      }

      var data = {
        schema: validation
      };
      $.ajax({
        cache: false,
        type: 'PUT',
        url: arangoHelper.databaseUrl('/_api/collection/' + this.get('id') + '/properties'),
        data: JSON.stringify(data),
        contentType: 'application/json',
        processData: false,
        success: function success() {
          callback(false);
        },
        error: function error(data) {
          callback(true, data);
        }
      });
      return result;
    }
  });
})();